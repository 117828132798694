import * as firebase from 'firebase/app';
import 'firebase/auth';

import { firebaseConfig } from 'config/constants';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const SECRETPASSWORD = 'SN*w2022&sleD';

export const signIn = ({ password }) => {
  return password === SECRETPASSWORD;
};

export const signUp = ({ email, password }) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const signOut = () => {
  return firebase.auth().signOut();
};

export const sendPasswordResetEmail = ({ email }) => {
  return firebase.auth().sendPasswordResetEmail(email);
};

export const confirmPasswordReset = ({ code, password }) => {
  return firebase.auth().confirmPasswordReset(code, password);
};

export const sendEmailVerification = () => {
  return firebase.auth().currentUser.sendEmailVerification();
};
