import styled from 'styled-components';

export const Header = styled.header`
  position: relative;
  background-image: url(https://1170617057.rsc.cdn77.org/images-snow-MY22/Site/Polaris-Snow22-Background_Top.jpg);
  background-position: center;
  background-repeat-x: no-repeat;
  background-size: cover;
  transition: transform .4s cubic-bezier(.4,0,.2,1);
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  svg {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const Logo = styled.div`
  padding: 1rem;
  img {
    display: block;
    margin: 0;
    max-width: 240px;
  }

  @media (min-width: 374px) {
    img {
      max-width: 280px;
    }
  }
  @media (min-width: 600px) {
    padding: 2rem 1rem;
    img {
      margin: auto;
      max-width: 80%;
    }
  }
`;


export const Hamburger = styled.div`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);

  background-color: #135195;
  color: #fff;
  width: 3rem;
  padding: 0.75rem;
  @media (min-width: 1140px) {
    display: none;
  }
`;
