const colors = {
  transparent: 'transparent',
  black: '#000000',
  error: '#f5222d',
  white: '#ffffff',
  headingColor: '#0f2137',
  textColor: '#5d646d',
  primary: '#1890ff',
  primaryHover: '#40a9ff',
  secondary: '#ff5b60',
  secondaryHover: '#FF282F',
  primaryBoxShadow: ' 0px 9px 19.74px 1.26px rgba(82, 104, 219, 0.57) '
};

export default colors;
