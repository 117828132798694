import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  svg {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const MobileUnderlay = styled.div`
  background-color: rgba(0,0,0,0.5);
  display: none;
  position: fixed;
  z-index: 102;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: transform .4s cubic-bezier(0,0,.2,1);

  .menu-open & {
    display: block;
  }

  @media (min-width: 1140px) {
    display: none;
    .menu-open & {
      display: none;
    }
  }
`;

export const FlexNavigation = styled.div`
  position: fixed;
  z-index: 103;
  top: 0;
  right: -240px;
  width: 240px;
  min-height: 100vh;
  text-align: left;
  transition: transform .4s cubic-bezier(0,0,.2,1);

  background-image: url(https://1170617057.rsc.cdn77.org/images-snow-MY22/Site/Polaris-Snow22-Background_Bottom.jpg);
  background-repeat: repeat-y;
  background-position: top center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;

  .menu-open & {
    transform: translateX(-240px);
  }

  @media (min-width: 1140px) {
    position: relative;
    top: auto;
    right: auto;
    width: auto;
    box-shadow: none;
    background-repeat: repeat-x;
    flex-direction: row;
    justify-content: center;
    min-height: 0;
    transition: none;

    .menu-open & {
      transform: translateX(0);
    }
  }
`;

export const FlexNavItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  justify-content: space-between;
  align-items: center;
  padding-right: .75rem;
  padding-left: .75rem;

  color: #23201F;
  cursor: default;
  font-weight: bold;

  &.red {
    color: #e62b33;
    border-bottom: 1px solid #e1e1e1;
  }
  &.blue {
    color: #135195;
    border-bottom: 1px solid #e1e1e1;
  }
  &.bg-blue {
    background-color: #1c539c;
    color: #fff;
  }
  &.bg-dk-blue {
    background-color: #174a90;
    color: #fff;
  }

  &.bg-blue,
  &.bg-dk-blue {
    a:hover {
      color: inherit;
    }
  }


  .menu-open.rmk &.rmk svg,
  .menu-open.switchback &.switchback svg,
  .menu-open.titan &.titan svg,
  .menu-open.indy &.indy svg,
  .menu-open.voyageur &.voyageur svg,
  .menu-open.timbersled &.timbersled svg,
  .menu-open.technology &.technology svg,
  .menu-open.pga &.pga svg,
  .menu-open.press-release &.press-release svg {
    transform: rotateZ(90deg);
  }

  @media (min-width: 1140px) {
    flex: 1 0 auto;
    padding-right: .5rem;
    padding-left: .5rem;
    position: relative;

    &.red,
    &.blue {
      border-bottom: none;
    }

    &.border {
      border-right: 1px solid #23201F;
    }

    &:hover .sub-menu {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const NavItemText = styled.span`
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  @media (min-width: 1140px) {
    padding-right: .5rem;
    padding-left: .5rem;
  }
`;

export const SubMenu = styled.div`
  .menu-open & {
    box-shadow: 4px 2px 8px rgb(0 0 0 / 10%);
    // transform: translateX(-240px);
  }

  background-color: #f8f8f8;
  display: none;
  margin: 0 -0.75rem;
  width: calc(100% + 1.5rem);
  text-align: left;

  .bg-blue &,
  .bg-dk-blue & {
    background-color: inherit;
  }


  a {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.75rem 0.5rem;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .nested-nav {
    margin-bottom: 1rem;
    padding-left: 0.75rem;
    a {
      &:first-child {
        margin: 0.75rem 0 0;
      }
    }
  }

  @media (min-width: 1140px) {
    position: absolute;
    left: 0;
    top: 100%;
    width: 200px;
    margin: 0;
    padding: 1.5rem;
    opacity: 0;
    visibility: hidden;
    box-shadow: 4px 2px 8px rgb(0 0 0 / 10%);

    &:hover {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    a {
      margin: 0.75rem 0 0;
      padding: 0
      &:first-child {
        margin: 0;
      }
    }
  }
  @media (max-width: 1139px) {
    .menu-open.rmk .rmk &,
    .menu-open.switchback .switchback &,
    .menu-open.titan .titan &,
    .menu-open.indy .indy &,
    .menu-open.voyageur .voyageur &,
    .menu-open.timbersled .timbersled &,
    .menu-open.technology .technology &,
    .menu-open.pga .pga &,
    .menu-open.press-release .press-release {
        display: block;
        margin: 0 -0.75rem;
    }
  }
`;

export const SubMenuTitle = styled.div`
  margin: 0.5rem 0 0.5rem 0.75rem;
  @media (min-width: 1140px) {
    margin: 0;
  }
`;


export const MoreIcon  = styled.div`
  cursor: pointer;
  flex: 0 1 auto;
  @media (min-width: 1140px) {
    display: none;
  }
`;