import styled from 'styled-components';

export const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-size: 0.875rem;
  padding: 0.75rem;
  text-align: center;
  transition: transform .4s cubic-bezier(.4,0,.2,1);
`;
