import colors from './colors';

// Use this setup to implement your theme
export const theme = {
  colors,
  colorStyles: {
    primary: {
      color: colors.primary,
      border: '1px solid',
      borderColor: colors.primary,
      backgroundColor: colors.transparent,
      '&:hover': {
        color: colors.white,
        backgroundColor: colors.primaryHover,
        borderColor: colors.transparent,
        boxShadow: '0px 9px 20px -5px rgba(82, 104, 219, 0.57)',
        backgroundImage:
          'linear-gradient( 31deg, rgba(215,178,233, 0.4) 0%, rgba(83,105,220, 0.4) 100%)'
      }
    },
    secondary: {
      color: colors.secondary,
      borderColor: colors.secondary,
      '&:hover': {
        color: colors.secondaryHover,
        borderColor: colors.secondaryHover
      }
    },
    warning: {
      color: colors.yellow,
      borderColor: colors.yellow,
      '&:hover': {
        color: colors.yellowHover,
        borderColor: colors.yellowHover
      }
    },
    error: {
      color: colors.secondaryHover,
      borderColor: colors.secondaryHover,
      '&:hover': {
        color: colors.secondary,
        borderColor: colors.secondary
      }
    }
  }
};
