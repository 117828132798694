import React from 'react';
import { NavLink } from 'react-router-dom';

import * as Styled from './styles';
import {
  rmk,
  switchback,
  titan,
  indy,
  voyageur,
  timbersled
} from 'data/model-families';

const BrandSidebarMenu = ({ family='', models={} }) => {
  return (
    <Styled.BrandSidebarMenu>
      {Object.keys(models).map(k => {
        return (
          <li key={k}>
            <NavLink to={`/${family}/${k}/`}>{models[k].title}</NavLink>
          </li>
        )
      })}
    </Styled.BrandSidebarMenu>
  )
};

const RMKSidebar = ({ models }) => {
  return (
    <Styled.BrandSidebar>
      <Styled.BrandBanner className="black">
        <img src="https://1170617057.rsc.cdn77.org/content-snow-MY19/logos/sidebar-1-RMK.png" alt="RMK" />
      </Styled.BrandBanner>
      <BrandSidebarMenu family='rmk' models={models}/>
    </Styled.BrandSidebar>
  );
};

const SwitchbackSidebar = ({ models }) => {
  return (
    <Styled.BrandSidebar>
      <Styled.BrandBanner className="black">
        <img src="https://1170617057.rsc.cdn77.org/content-snow-MY19/logos/sidebar-2-Switchback.png" alt="Switchback" />
      </Styled.BrandBanner>
      <BrandSidebarMenu family='switchback' models={models}/>
    </Styled.BrandSidebar>
  );
};

const TITANSidebar = ({ models }) => {
  return (
    <Styled.BrandSidebar>
      <Styled.BrandBanner className="black">
        <img src="https://1170617057.rsc.cdn77.org/content-snow-MY19/logos/sidebar-3-Titan.png" alt="TITAN" />
      </Styled.BrandBanner>
      <BrandSidebarMenu family='titan' models={models}/>
    </Styled.BrandSidebar>
  );
};

const INDYSidebar = ({ models }) => {
  return (
    <Styled.BrandSidebar>
      <Styled.BrandBanner className="black">
        <img src="https://1170617057.rsc.cdn77.org/content-snow-MY19/logos/sidebar-5-Indy.png" alt="INDY" />
      </Styled.BrandBanner>
      <BrandSidebarMenu family='indy' models={models}/>
    </Styled.BrandSidebar>
  );
};

const VoyageurSidebar = ({ models }) => {
  return (
    <Styled.BrandSidebar>
      <Styled.BrandBanner className="black">
        <img src="https://1170617057.rsc.cdn77.org/content-snow-MY19/logos/sidebar-6-Voyageur.png" alt="Voyageur" />
      </Styled.BrandBanner>
      <BrandSidebarMenu family='voyageur' models={models}/>
    </Styled.BrandSidebar>
  );
};

const TimbersledSidebar = ({ models }) => {
  return (
    <Styled.BrandSidebar>
      <Styled.BrandBanner className="black">
        <img src="https://1170617057.rsc.cdn77.org/content-snow-MY19/logos/sidebar-7-Timbersled-new.png" alt="TIMBERSLED" />
      </Styled.BrandBanner>
      <BrandSidebarMenu family='timbersled' models={models}/>
    </Styled.BrandSidebar>
  );
};

const SideBar = ({ showRMK, showSwitchback, showTITAN, showINDY, showVoyageur, showTimbersled, modelDownloads, children }) => {
  return (
    <Styled.Wrapper>
      {showRMK && <RMKSidebar models={rmk}/>}
      {showSwitchback && <SwitchbackSidebar models={switchback}/>}
      {showTITAN && <TITANSidebar models={titan}/>}
      {showINDY && <INDYSidebar models={indy}/>}
      {showVoyageur && <VoyageurSidebar models={voyageur}/>}
      {showTimbersled && <TimbersledSidebar models={timbersled}/>}
      
      {children}
    </Styled.Wrapper>
  );
};

export default SideBar;
