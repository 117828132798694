import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AuthenticatedRoute = ({ authenticated, Component, ...rest }) => {
  return (
    <div>
      {authenticated ? <Route {...rest} render={props => <Component {...props} />} /> : <Redirect to='/sign-in' />
      }
    </div>
  )
};

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated
  };
};

export default connect(mapStateToProps, {})(AuthenticatedRoute);
