import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';

import {
  rmk,
  switchback,
  titan,
  indy,
  voyageur,
  timbersled
} from 'data/model-families';
import * as Styled from './styles';

const ModelSubMenu = ({ family = '', models = {}, handleClick }) => {
  return (
    <Styled.SubMenu className="sub-menu" onClick={handleClick}>
      <nav>
        {Object.keys(models).map(k => {
          return (
            <NavLink key={k} to={`/${family}/${k}/`}>{models[k].title}</NavLink>
          )
        })}
      </nav>
    </Styled.SubMenu>
  );
};

const TechnologySubMenu = () => {
  return (
    <Styled.SubMenu className="sub-menu">
      <Styled.SubMenuTitle>Engines</Styled.SubMenuTitle>
      <nav className="nested-nav">
        <NavLink to="/technology/550-liberty">550 Liberty</NavLink>
        <NavLink to="/technology/600-cleanfire">600 Cleanfire</NavLink>
        <NavLink to="/technology/800-cleanfire-ho">800 Cleanfire H.O.</NavLink>
        <NavLink to="/technology/850-patriot">850 Patriot</NavLink>
        <NavLink to="/technology/650-patriot">650 Patriot</NavLink>
        <NavLink to="/technology/patriot-boost">Patriot Boost</NavLink>
      </nav>
    </Styled.SubMenu>
  );
};

const PGASubMenu = () => {
  return (
    <Styled.SubMenu className="sub-menu">
      <Styled.SubMenuTitle>Snowmobiles</Styled.SubMenuTitle>
      <nav className="nested-nav">
        <NavLink to="/pga/snowmobiles-accessories">Accessories</NavLink>
        <NavLink to="/pga/snowmobiles-apparel">Apparel</NavLink>
      </nav>
      <Styled.SubMenuTitle>Timbersled</Styled.SubMenuTitle>
      <nav className="nested-nav">
        <NavLink to="/pga/timbersled-accessories">Accessories</NavLink>
        <NavLink to="/pga/timbersled-apparel">Apparel</NavLink>
      </nav>
    </Styled.SubMenu>
  );
};

const SpecsSubMenu = () => {
  return (
    <Styled.SubMenu className="sub-menu">
      <nav>
        <NavLink to="/specs#trail">Trail</NavLink>
        <NavLink to="/specs#mountain">Mountain &amp; Crossover</NavLink>
        <NavLink to="/specs#timbersled">Timbersled</NavLink>
      </nav>
    </Styled.SubMenu>
  );
};

const ImagesSubMenu = () => {
  return (
    <Styled.SubMenu className="sub-menu">
      <nav>
        <NavLink to="/images#rmk">RMK</NavLink>
        <NavLink to="/images#switchback">Switchback</NavLink>
        <NavLink to="/images#titan">TITAN</NavLink>
        <NavLink to="/images#indy">INDY</NavLink>
        <NavLink to="/images#voyageur">Voyageur</NavLink>
        <NavLink to="/images#timbersled">Timbersled</NavLink>
        <NavLink to="/images/pga">PG&A</NavLink>
        <NavLink to="/images/technology">Technology</NavLink>
        <NavLink to="/images/logos">Logos</NavLink>
      </nav>
    </Styled.SubMenu>
  );
};

const PressReleasesSubMenu = () => {
  return (
    <Styled.SubMenu className="sub-menu">
      <nav>
        <NavLink to="/press-releases/snowmobiles">Snowmobiles</NavLink>
        <NavLink to="/press-releases/timbersled">Timbersled</NavLink>
      </nav>
    </Styled.SubMenu>
  );
};

const MoreIcon = ({ handleClick }) => {
  return (
    <Styled.MoreIcon onClick={handleClick}>
      <ChevronRightIcon />
    </Styled.MoreIcon>
  )
};

const Nav = ({ menuOpen, activeSubMenu, toggleMobileMenu, updateActiveSubMenu }) => {
  return (
    <Styled.Container className={menuOpen ? `menu-open ${activeSubMenu}` : ''}>
      <Styled.MobileUnderlay onClick={toggleMobileMenu} />
      <Styled.FlexNavigation>
        <Styled.FlexNavItem className="rmk blue border">
          <Styled.NavItemText>RMK</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('rmk')} />
          <ModelSubMenu family="rmk" models={rmk} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="switchback red border">
          <Styled.NavItemText>Switchback</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('switchback')} />
          <ModelSubMenu family="switchback" models={switchback} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="titan blue border">
          <Styled.NavItemText>TITAN</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('titan')} />
          <ModelSubMenu family="titan" models={titan} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="indy red border">
          <Styled.NavItemText>INDY</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('indy')} />
          <ModelSubMenu family="indy" models={indy} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="voyageur blue border">
          <Styled.NavItemText>Voyageur</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('voyageur')} />
          <ModelSubMenu family="voyageur" models={voyageur} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="timbersled border">
          <Styled.NavItemText>Timbersled</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('timbersled')} />
          <ModelSubMenu family="timbersled" models={timbersled} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="technology bg-dk-blue">
          <Styled.NavItemText>Technology</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('technology')} />
          <TechnologySubMenu handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="pga bg-blue">
          <Styled.NavItemText>PG&A</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('pga')} />
          <PGASubMenu handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="specs bg-dk-blue">
          <Styled.NavItemText>
            <NavLink to="/specs">SPECS</NavLink>
          </Styled.NavItemText>
          <SpecsSubMenu />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="images bg-blue">
          <Styled.NavItemText>
            <NavLink to="/images">IMAGES</NavLink>
          </Styled.NavItemText>
          <ImagesSubMenu />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="bg-dk-blue press-release">
          <Styled.NavItemText>PRESS RELEASES</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('press-release')} />
          <PressReleasesSubMenu handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
      </Styled.FlexNavigation>
    </Styled.Container>
  );
};

export default Nav;
