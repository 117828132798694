import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
`;

const Main = props => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default Main;
