import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import FourOhFour from 'pages/FourOhFour/FourOhFour';
import Loader from './components/Loader/Loader';
import AuthenticatedRoute from './helpers/AuthenticatedRoute';

const AsyncHome = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './pages/Home/Home'),
  loading: Loader
});

const AsyncModel = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Model" */ './pages/Model/Model'),
  loading: Loader
});

const AsyncFiveFiftyLiberty = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FiveFiftyLiberty" */ './pages/Technology/550-Liberty'),
  loading: Loader
});
const AsyncSixHundredCleanfire = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SixHundredCleanfire" */ './pages/Technology/600-Cleanfire'),
  loading: Loader
});
const AsyncSixFiftyPatriot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SixFiftyPatriot" */ './pages/Technology/650-Patriot'),
  loading: Loader
});
const AsyncEightHundredCleanfire = Loadable({
  loader: () =>
    import(/* webpackChunkName: "EightHundredCleanfire" */ './pages/Technology/800-Cleanfire'),
  loading: Loader
});
const AsyncEightFiftyPatriot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "EightFiftyPatriot" */ './pages/Technology/850-Patriot'),
  loading: Loader
});
const AsyncPatriotBoost = Loadable({
  loader: () =>
    import(/* webpackChunkName: "PatriotBoost" */ './pages/Technology/Patriot-Boost'),
  loading: Loader
});

export const AsyncSnowAccessories = Loadable({
  loader: () => import(/* webpackChunkName: "SnowAccessories" */ './pages/PGA/Snowmobiles-Accessories'),
  loading: Loader
});
export const AsyncSnowApparel = Loadable({
  loader: () => import(/* webpackChunkName: "SnowApparel" */ './pages/PGA/Snowmobiles-Apparel'),
  loading: Loader
});
export const AsyncTimberAccessories = Loadable({
  loader: () => import(/* webpackChunkName: "TimberAccessories" */ './pages/PGA/Timbersled-Accessories'),
  loading: Loader
});
export const AsyncTimberApparel = Loadable({
  loader: () => import(/* webpackChunkName: "TimberApparel" */ './pages/PGA/Timbersled-Apparel'),
  loading: Loader
});

export const AsyncSpecs = Loadable({
  loader: () => import(/* webpackChunkName: "Specs" */ './pages/Specs/Specs'),
  loading: Loader
});

export const AsyncImages = Loadable({
  loader: () => import(/* webpackChunkName: "Images" */ './pages/Images/Images'),
  loading: Loader
});

export const AsyncImageGallery = Loadable({
  loader: () => import(/* webpackChunkName: "ImageGallery" */ './pages/Images/Gallery'),
  loading: Loader
});

export const AsyncSignIn = Loadable({
  loader: () => import(/* webpackChunkName: "SignIn" */ './pages/Auth/SignIn'),
  loading: Loader
});

const Routes = props => {
  return (
    <Switch>
      <AuthenticatedRoute path='/' exact component={AsyncHome} />
      {/* Model Pages */}
      <AuthenticatedRoute path='/rmk/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/switchback/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/titan/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/indy/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/voyageur/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/timbersled/:id' component={AsyncModel} history={props.history} />
      {/* Technology */}
      <AuthenticatedRoute path='/technology/550-liberty' exact component={AsyncFiveFiftyLiberty} />
      <AuthenticatedRoute path='/technology/600-cleanfire' exact component={AsyncSixHundredCleanfire} />
      <AuthenticatedRoute path='/technology/800-cleanfire-ho' exact component={AsyncEightHundredCleanfire} />
      <AuthenticatedRoute path='/technology/850-patriot' exact component={AsyncEightFiftyPatriot} />
      <AuthenticatedRoute path='/technology/650-patriot' exact component={AsyncSixFiftyPatriot} />
      <AuthenticatedRoute path='/technology/patriot-boost' exact component={AsyncPatriotBoost} />
      {/* PG&A */}
      <AuthenticatedRoute path='/pga/snowmobiles-accessories' exact component={AsyncSnowAccessories} />
      <AuthenticatedRoute path='/pga/snowmobiles-apparel' exact component={AsyncSnowApparel} />
      <AuthenticatedRoute path='/pga/timbersled-accessories' exact component={AsyncTimberAccessories} />
      <AuthenticatedRoute path='/pga/timbersled-apparel' exact component={AsyncTimberApparel} />
      {/* Specs */}
      <AuthenticatedRoute path='/specs' exact component={AsyncSpecs} />
      {/* Images */}
      <AuthenticatedRoute path='/images' exact component={AsyncImages} />
      <AuthenticatedRoute path='/images/rmk/:id' component={AsyncImageGallery} history={props.history} />
      <AuthenticatedRoute path='/images/switchback/:id' component={AsyncImageGallery} history={props.history} />
      <AuthenticatedRoute path='/images/titan/:id' component={AsyncImageGallery} history={props.history} />
      <AuthenticatedRoute path='/images/indy/:id' component={AsyncImageGallery} history={props.history} />
      <AuthenticatedRoute path='/images/voyageur/:id' component={AsyncImageGallery} history={props.history} />
      <AuthenticatedRoute path='/images/timbersled/:id' component={AsyncImageGallery} history={props.history} />
      <AuthenticatedRoute path='/images/logos' component={AsyncImageGallery} history={props.history} />
      <AuthenticatedRoute path='/images/pga' component={AsyncImageGallery} history={props.history} />
      <AuthenticatedRoute path='/images/technology' component={AsyncImageGallery} history={props.history} />
      {/* Press Releases */}
      <Route path='/sign-in' exact component={AsyncSignIn} />
      <Route component={FourOhFour} />
    </Switch>
  );
};

export default Routes;
