import * as actions from './actions';
import * as sharedActions from '../shared/actions';

const defaultState = {
  authenticated: false,
  isLoading: false,
  isLoaded: false,
  message: null
};

function auth(state = defaultState, { type, authenticated, message }) {
  switch (type) {
    case actions.SIGN_UP:
    case actions.SIGN_IN:
    case actions.SIGN_OUT:
    case actions.SEND_PASSWORD_RESET_EMAIL:
    case actions.CONFIRM_PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        message: null
      };
    case actions.SIGN_UP_SUCCESS:
    case actions.SIGN_IN_SUCCESS:
    case actions.SIGN_OUT_SUCCESS:
    case actions.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
    case actions.CONFIRM_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        authenticated,
        message,
        isLoading: false,
        isLoaded: true
      };
    case actions.SIGN_UP_FAIL:
    case actions.SIGN_IN_FAIL:
    case actions.SIGN_OUT_FAIL:
    case actions.SEND_PASSWORD_RESET_EMAIL_FAIL:
    case actions.CONFIRM_PASSWORD_RESET_FAIL:
      return {
        ...state,
        message,
        isLoading: false,
        isLoaded: false
      };
    case actions.SET_USER:
      return {
        ...state,
        authenticated: authenticated || false
      };
    case sharedActions.CLEAR_MESSAGES:
      return {
        ...state,
        message: null
      };

    default:
      return state;
  }
}

export default auth;
