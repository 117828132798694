import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: .5rem;
  padding-left: .5rem;
  margin-bottom: 2rem;

  flex-basis: 100%;
  max-width: 100%;

  @media (min-width: 768px) {
    margin-bottom: 0;
    flex-basis: calc(100% - 260px);
    max-width: calc(100% - 260px);

    &:only-child {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  @media (min-width: 1000px) {
    flex-basis: calc(100% - 300px);
    max-width: calc(100% - 300px);

    &:only-child {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  h1, h2, h3 {
    margin-top: 0;
  }
`;

const Content = props => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default Content;
