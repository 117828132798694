import React from 'react';
import Helmet from 'react-helmet';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Content from 'components/Layout/Content';
import SideBar from 'components/SideBar/SideBar';

import * as Styled from './styles';

const FourOhFour = props => {
  return (
    <>
      <Helmet>
        <title>Page Not Found | Polaris Presskit</title>
      </Helmet>
      <Header history={props.history} />
      <Styled.Container>
        <Styled.Grid>
          <Content>
            <center>
              <h1>Page not found</h1>
            </center>
          </Content>
          <SideBar showRMK={true} showSwitchback={true} showTITAN={true} showINDY={true} showVoyageur={true} showTimbersled={true} />
        </Styled.Grid>
      </Styled.Container>
      <Footer />
    </>
  );
};

export default FourOhFour;
