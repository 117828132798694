import { isLocalhost } from '../serviceWorker';

export const rootUrl = isLocalhost
  ? 'http://localhost:3000/'
  : 'https://preview-firebase.reactmilkshake.com/';

export const firebaseConfig = {
  apiKey: 'XXXXXX',
  authDomain: 'XXXXXX.firebaseapp.com',
  databaseURL: 'https://XXXXXX.firebaseio.com',
  projectId: 'XXXXXX',
  storageBucket: 'XXXXXX.appspot.com',
  messagingSenderId: 'XXXXXX',
  appId: 'XXXXXX'
};

export const messageTypes = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  INFO: 'INFO'
};
