export const rmk = {
    'rmk-evo': {
        title: 'RMK EVO',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/thumbs/RMK_Evo.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_Evo.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_Evo-FR.pdf',
    },
    'axys-rmk-144': {
        title: 'AXYS RMK 144',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/thumbs/RMK_144.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_144.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_144-FR.pdf',
    },
    'axys-pro-rmk': {
        title: 'AXYS PRO RMK',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/thumbs/RMK_Pro.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_Pro.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_Pro-FR.pdf',
    },
    'axys-rmk-khaos': {
        title: 'AXYS RMK KHAOS',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/thumbs/RMK_Khaos.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_Khaos.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_Khaos-FR.pdf',
    },
    'matryx-pro-rmk': {
        title: 'PRO RMK MATRYX',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/thumbs/RMK_ProRMKMatryx.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_ProRMKMatryx.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_ProRMKMatryx-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20RMK%20Matryx%20Walkaround.pdf',
    },
    'matryx-rmk-khaos': {
        title: 'RMK KHAOS MATRYX',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/thumbs/RMK_RMKKhaosMatryx.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_RMKKhaosMatryx.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_RMKKhaosMatryx-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20RMK%20Matryx%20Walkaround.pdf',
    },
    'matryx-slash-pro-rmk': {
        title: 'PRO RMK MATRYX SLASH',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/thumbs/RMK_ProRMKMatryxSlash.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_ProRMKMatryxSlash.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_ProRMKMatryxSlash-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20RMK%20Matryx%20Slash%20Walkaround.pdf',
    },
    'matryx-slash-rmk-khaos': {
        title: 'RMK KHAOS MATRYX SLASH',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/thumbs/RMK_RMKKhaosMatryxSlash.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_RMKKhaosMatryxSlash.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/RMK/hirez/RMK_RMKKhaosMatryxSlash-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20RMK%20Matryx%20Slash%20Walkaround.pdf',
    },
};

export const switchback = {
    'switchback-assault': {
        title: 'Switchback Assault',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Switchback/thumbs/Switchback_Assault.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Switchback/hirez/Switchback_Assault.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Switchback/hirez/Switchback_Assault-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20Switchback%20Walkaround.pdf',
    },
    'switchback-xc': {
        title: 'Switchback XC',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Switchback/thumbs/Switchback_XC.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Switchback/hirez/Switchback_XC.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Switchback/hirez/Switchback_XC-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20Switchback%20Walkaround.pdf',
    },
};

export const titan = {
    'titan-xc': {
        title: 'TITAN XC',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Titan/thumbs/Titan_XC.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Titan/pdf/Titan_XC.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Titan/pdf/Titan_XC-FR.pdf',
    },
    'titan-adventure': {
        title: 'TITAN ADVENTURE',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Titan/thumbs/Titan_Adventure.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Titan/pdf/Titan_Adventure.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Titan/pdf/Titan_Adventure-FR.pdf',
    },
};

export const indy = {
    "120-indy" : {
        title: "120 INDY",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_120.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_120.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_120-FR.pdf',
    },
    "indy-evo" : {
        title: "INDY EVO",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_Evo.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_Evo.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_Evo-FR.pdf',
    },
    "550-indy" : {
        title: "550 INDY",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy-FR.pdf',
    },
    "550-indy-adventure" : {
        title: "550 INDY Adventure",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_550Adventure.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_550Adventure.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_550Adventure-FR.pdf',
    },
    "indy-lxt" : {
        title: "INDY LXT",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_LXT.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_LXT.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_LXT-FR.pdf',
    },
    "indy-sp" : {
        title: "INDY SP",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_SP.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_SP.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_SP-FR.pdf',
    },
    "indy-xc" : {
        title: "INDY XC",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_XC.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_XC.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_XC-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20INDY%20Walkaround.pdf',
    },
    "indy-adventure" : {
        title: "INDY Adventure",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_Adventure.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_Adventure.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_Adventure-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20INDY%20Walkaround.pdf',
    },
    "indy-xcr" : {
        title: "INDY XCR",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_XCR.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_XCR.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_XCR-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20INDY%20XCR%20Walkaround.pdf',
    },
    "indy-vr1" : {
        title: "INDY VR1",
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/thumbs/Indy_VR1.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_VR1.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/INDY/hirez/Indy_VR1-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20INDY%20Walkaround.pdf',
    }
};

export const voyageur = {
    '550-voyageur': {
        title: '550 Voyageur',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Voyageur/thumbs/Voyageur.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Voyageur/hirez/Voyageur.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Voyageur/hirez/Voyageur-FR.pdf',
    },
    'voyageur-146': {
        title: 'Voyageur 146',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Voyageur/thumbs/Voyageur_146.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Voyageur/hirez/Voyageur_146.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Voyageur/hirez/Voyageur_146-FR.pdf',
        walkaround: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Walkarounds/MY22%20Voyageur%20146%20Walkaround.pdf',
    },
};

export const timbersled = {
    'ripper': {
        title: 'Ripper',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/thumbs/Timbersled_Ripper.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_Ripper.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_Ripper-FR.pdf',
    },
    'riot': {
        title: 'RIOT',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/thumbs/Timbersled_RIOT.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_RIOT.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_RIOT-FR.pdf',
    },
    'riot-3': {
        title: 'RIOT 3',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/thumbs/Timbersled_RIOT3.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_RIOT3.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_RIOT3-FR.pdf',
    },
    'aro-129-137': {
        title: 'ARO 129 & 137',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/thumbs/Timbersled_ARO.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_ARO.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_ARO-FR.pdf',
    },
    'aro-3': {
        title: 'ARO 3',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/thumbs/Timbersled_ARO3.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_ARO3.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_ARO3-FR.pdf',
    },
    '120-sx': {
        title: '120 SX',
        src: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/thumbs/Timbersled_120SX.jpg',
        pdf: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_120SX.pdf',
        french: 'https://1170617057.rsc.cdn77.org/content-snow-MY22/Timbersled/hirez/Timbersled_120SX-FR.pdf'
    }
};
