import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Nav from 'components/Nav/Nav';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import * as Styled from './styles';

const Logo = () => {
  return (
    <Styled.Logo>
      <NavLink to="/">
        <img src="https://1170617057.rsc.cdn77.org/images-snow-MY22/Site/Polaris-Snow22-Header_web.png" alt="Polaris MY 2022 Snow Press Kit" />
      </NavLink>
    </Styled.Logo>
  )
}

const Header = ({ history }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const toggleMobileMenu = () => {
    if (menuOpen) {
      setActiveSubMenu('');
    }
    setMenuOpen(!menuOpen);
  };

  const updateActiveSubMenu = (value) => {
    console.log('updateActiveSubMenu', value)
    if (value === activeSubMenu) {
      setActiveSubMenu('');
    } else {
      setActiveSubMenu(value);
    }
  };

  return (
    <Styled.Header className={menuOpen ? 'mobile-menu-open' : ''}>
      <Styled.Container>
        <Logo />
        <Styled.Hamburger onClick={toggleMobileMenu}>
          {menuOpen ? <CloseIcon /> : <MenuIcon/>}
        </Styled.Hamburger>
      </Styled.Container>
      <Nav menuOpen={menuOpen} activeSubMenu={activeSubMenu} toggleMobileMenu={toggleMobileMenu} updateActiveSubMenu={updateActiveSubMenu} />
    </Styled.Header>
  );
};

export default Header;
