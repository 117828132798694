import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import Routes from './routes';
import Main from './components/Layout/Main';

const TRACKING_ID = "UA-192434352-1";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const history = useHistory();
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
  return (
    <Main>
      <Routes history={history} />
    </Main>
  );
};

export default App;
