import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column-reverse;
  padding-right: .5rem;
  padding-left: .5rem;

  flex-basis: 100%;
  max-width: 100%;

  @media (min-width: 768px) {
    flex-direction: column;
    flex-basis: 260px;
    max-width: 260px;
  }
`;

export const BrandSidebar = styled.div`
  margin-bottom: 1.5rem;
`;

export const BrandBanner = styled.div`
  img {
    display: block;
    margin: auto;
  }

  &.black {
    background-color: #000;
  }
`;

export const BrandSidebarMenu = styled.ul`
  padding: 0 10px 0 20px;

  li {
    margin-bottom: 3px;
  }

  a {
    display: block;
    font-size: 0.875rem;
    line-height: 20px;

    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;


export const ModelDownloads = styled.div`
  margin-bottom: 2rem;

  .btn {
    display: block;
    width: 100%;
    margin: 0 0 1rem;
  }

  @media (min-width: 768px) {
    margin-top: 2rem;
  }
`;